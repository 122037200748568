import {frSimulations} from '@/locales/fr/simulationsFr';

export const fr = {
  simulations: frSimulations,
  assignments: 'Devoirs',
  english: 'Anglais',
  french: 'Français',
  'assignment-overview': 'Aperçu des devoirs',
  'see-all-assignments': 'Voir tous les devoirs',
  announcement: 'Annonce',
  dashboard: 'Tableau de bord',
  'my-profile': 'Mon profil',
  'view-profile': 'Voir le profil',
  'change-password': 'Changer le mot de passe',
  'log-out': 'Se déconnecter',
  timeline: 'Chronologie',
  'super-admin': 'Super administrateur',
  cancel: 'Annuler',
  grade: 'Note',
  grades: 'Notes',
  completed: 'Terminé',
  'in-progress': 'En cours',
  submitted: 'Soumis',
  due: 'Dû',
  performance: 'Performance',
  'more-info': "Plus d'informations",
  allAssignments: 'Tous les devoirs',
  assignmentOverviewText: 'Vous trouverez tous vos devoirs pour {courseName} ici',
  'have-not-started': 'Non commencé',
  'grade-is-locked': "La note n'est pas encore visible",
  started: 'Commencé',
  graded: 'Évalué',
  question: 'Question',
  'preview-assignment': 'Prévisualiser le devoir',
  'start-assignment': 'Aller au devoir',
  'go-to-assignment': 'Aller au devoir',
  'payment-required':
    'Vous avez des cours qui nécessitent un paiement. Veuillez visiter votre profil pour commencer le processus de paiement.',
  dashboardPage: {
    paymentSuccessful:
      'Votre paiement a été effectué avec succès. Si vous ne voyez pas votre cours, veuillez contacter le support à',
    ariaLabels: {
      timeline: 'naviguer vers cet élément de la chronologie',
    },
    title: 'Tableau de bord',
    tooltips: {
      timeline: "Cliquez ici pour naviguer vers l'élément de la chronologie",
    },
    freeTrialEnding: 'Fin de l’essai gratuit',
    welcome: {
      title: 'Bienvenue sur Stemble !',
      message: `Nous sommes ravis de vous accueillir à bord ce semestre. Si vous avez des commentaires ou
          rencontrez des problèmes, n’hésitez pas à nous contacter à support@stemble.com.`,
    },
  },
  paymentAlert: {
    trialAccessExpired: 'Oh non! Votre accès d’essai gratuit à {course} a expiré!',
    trialAccessExpiring:
      'Oh non! Votre accès d’essai gratuit à {course} expirera bientôt le {dueDate}!',
    missingOut: 'Vous pourriez manquer des devoirs importants.',
    renew: 'Vous pouvez renouveler votre abonnement en allant à la page de facturation.',
    goToBilling: 'Aller à la facturation',
  },
  drawer: {
    assignments: 'Devoirs',
    'grade-table': 'Tableau des notes',
    courseHome: 'Page du cours',
    participants: 'Participants',
    'staff-room': 'Salle du personnel',
    analytics: 'Analytique',
    pastCourses: 'Cours passés',
    courseSettings: 'Paramètres',
    createCourse: 'Créer un cours',
    createTask: 'Créer une tâche',
  },
  newAssignmentValidation: {
    chooseACourse: 'Choisir un cours',
  },
  assignmentsPage: {
    title: 'Titre',
    totalMarks: 'Total des points disponibles',
    assignmentWeight: 'Poids',
    createdOn: 'Créé le',
    createdBy: 'Créé par',
    addNewAssignment: 'Créer un nouveau devoir',
    duplicateSelectedAssignments: 'Dupliquer les devoirs sélectionnés',
    'delete-assignments': 'Supprimer les devoirs',
    'delete-assignments-confirmation': 'Êtes-vous sûr de vouloir supprimer ces devoirs?',
  },
};
