export const institution = 'Institution';
export const course = 'Course';
export const section = 'Section';
export const sections = `${section}s`;
export const courseName = `${course} Name`;
export const sectionName = `${section} Name`;
export const courseCode = `${course} Code`;
export const labTutorial = 'Lab/Tutorial';
export const labTutorialCourse = `${labTutorial} ${course}`;

export const courseSettings = {
  courseDataHeader: `${course} Data`,
  sectionsHeader: sections,
  subCourseDataHeader: `${labTutorial} Data`,
  addSection: `Add ${section}`,
  addSubCourse: `Add ${labTutorialCourse}`,
  editSubCourse: `Edit ${labTutorialCourse}`,
  removeSubCourse: `Remove ${labTutorialCourse}`,
  sectionName: sectionName,
  courseCode,
  description: 'Description',
  institution,
  startAndEndDates: 'Start and End Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  settingsSaved: 'Course settings saved.',
  editSections: `Edit sections`,
};
